export const m = 
{
    lqlj:"확인하세요",
sqinfo:"개인 계정을 사용하려면 지갑 인증을 사용하십시오",
sqbtn:"지갑 인증",
sqload:"라이선스로...",
dltt:"계정에 로그인",
dlinfo:"개인 계정을 사용하려면 지갑 링크를 클릭하세요",
dlbtn:"링크 지갑",
bdtt:"상위 바인딩",
bdinfo:"추천인의 지갑 주소를 입력해주세요",
bdbtn:"바인딩 확인",
bdload:"바인딩 중...",
lqsg:"성공했습니다",
lqsb:"수신에 실패했습니다",
day:"하루",
shi:"시간",
fen:"나누다",
미오:"초\"입니다",
fzcg:"성공적으로 복제했습니다",
fzsb:"복제 실패",
yhdj:"사용자 레벨",
bdcg:"성공적으로 바인딩했습니다",
notice:"ARCT는 세계 최초의 지능형 컴퓨팅 통합 서비스 네트워크 구축을 기반으로 한 web3 지능형 컴퓨팅 금융 플랫폼이며 세계 최초의 무간섭, 자동화 운행을 실현한 금융 서비스 플랫폼이다.",
cyarc:"ARCT 참여",
ljyy:"바로 예약하세요",
yysl:"예약 수량",
xzsl:"개수 선택",
qr:"확인",
wdldx:"나의 유동성",
wdlp:"나의 lp 값",
wdqydd:"제 계약서의 주문서",
jrgtxq:"ARCT와 함께 defi의 새로운 물결을 일으키자!",
qyc:"계약 풀",
cbdz:"소지권 주소",
wdqy:"나의 계약",
qysy:"계약 수익",
qrlq:"수령 확인",
lq:"받아요",
mx:"상세한 자료",
dqjl:"커뮤니티 보상",
ny:"있잖아요",
wcqbqr:"지갑 확인 완료",
zyyyyd:"주의사항:24시간내에 한건의 주문만 예약할수 있다",
dqsl:"공인 수량",
yycg:"성공적으로 참여하다",
yysb:"참여 실패",
mysj:"데이터가 없어",
sqjd:"커뮤니티 노드",
gsjd:"컨센서스 노드",
dqjx:"커뮤니티 캠페인",
gsjdzsl:"컨센서스 노드의 총 수",
gsjdydcsl:"컨센서스 노드의 합의된 개수",
dqgsjdjc:"현재 컨센서스 노드 프라이즈 풀",
wdjdjc:"내 노드 보너스 풀",
wdcjd:"실패한 노드 입니다",
ydcjd:"완료된 노드",
djzlmm:"총 노드 51개, 섹션 실적이 2w 달러에 도달하면 순서대로 노드 된다.",
jdmx:"노드 정보",
xh:"일련번호",
dz:"주소요",
sqjxjc:"커뮤니티 캠페인 풀",
wdsqjxjc:"내 커뮤니티 캠페인 풀",
xqyjmm:"소구역 업적이 15,000달러에 달했고, 상위 51위까지는 선거풀 상금을 받게 됩니다.",
sqpm:"커뮤니티 순위",
pm:"순위",
xqyj:"커뮤니티 업적",
zyj:"전체 업적입니다",
dq:"커뮤니티",
wdyqlj:"내 초대장 링크",
fz:"복제",
qysqtj:"계약 커뮤니티 통계",
zjtjhy:"직접 회원을 추천합니다",
xqyjzje:"소구역 실적 총금액",
sqyjzje:"총 커뮤니티 업적",
wdfxmx:"내 공유 명세서",
wx:"유효하지 않습니다",
위dyydd:"나의 예약주문",
ywcdqydd:"완료된 계약 주문서",
dzf:"지불을 기다린다",
yyz:"예약중입니다",
dyz:"저당잡히기에요",
qyje:"계약된 금액",
yydjs:"카운트다운 예약",
zydjs:"저당 잡힌 카운트다운",
zfdjs:"지불 카운트다운",
wcqydd:"계약 주문을 완료하세요",
ywc:"완료",
ysh:"지불했습니다",
zysl:"담보의 수량",
jydjs:"압송 카운트다운",
shqydd:"상환 계약 주문서",
wcqyddxyzf:"예약주문 완료시 지불이 필요합니다",
shcg:"성공적인 보상",
shsb:"상환 실패입니다",
zfcg:"결제 성공",
zfsb:"지불 실패",
wdfx:"나의 공유",
yxyh:"유효한 사용자",
wxyh:"잘못된 사용자",
grtr:"개인적투자",
sqyj:"커뮤니티 업적",
sfjd:"노드 여부",
shi:"그렇다",
fou:"아니요",
home:"홈",
wdsq:"나의 커뮤니티",
jdjl:"노드 보상",
jxjl:"선거 상금이요",
tx:"현금 인출",
jl:"보상 이요",
sl:"수량",
swjryy:"예약에 가입하지 않아 친구를 공유할 수 없습니다",
wdtjdz:"내 추천 주소",
qqdd:"지갑 상호 작용 요청",
dkndqb:"주문을 계속하려면 지갑을 열고 거래 활동에서 확인하십시오.",
zzclsw:"ARCT는 블록체인 거래를 하고 있으니 거래 결제를 기다려 주십시오",
ddz:"대기 중",
dqzdbmz:"현재 주소가 초대 조건을 충족하지 않습니다!",
bklq:"수령불가!",
nlcje:"에너지 풀",
wdnl:"에너지수량을받을수있다",
nlcze:"나의 에너지 수치",
ytxnlz:"에너지 수량을 수령하였다",
zwkf:"팁: 아직 오픈하지 않았습니다!",

xzsldj:"산력 등급을 선택하다",
  qbye:"지갑 잔고",
  njhdsle:"8배 (정적 2.6배, 동적 5.4배)의 수익을 얻습니다.",
  ljcy:"즉시 참여하다",
  jtsy:"정태 이익",
  dlqsy:"지급대기이익",
  jqsy:"금일 수익",
  ljsy:"누적 수익",
  dtsy:"동적 이익",
  wdsl:"나의 산력",
  sul:'계산 력',
  jrsf:"오늘 석방되다",
  jtsys:"정적 잉여",
  dtsys:"동적 잉여",

  wddj:"나의 등급",
  sfdjd:"큰 노드 여부",
  sfxjd:"작은 노드 여부",
  sffwzx:"서비스센터인지 아닌지는",
  gryj:"개인 업적",
  yebz:"잔액이 부족하다",
  phb:"순위표",
  rph:"일일 순위",
  zph:"주간 순위",
  yph:"월간 순위"
}